import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FORMDATA } from '../../constants/ajaxBodyTypes';
import { showErrorNotification } from '../../helpers/showNotification';
import { callApi } from '../../middlewares/apiMiddleware';
import { loadChunkURL, editChunkURL } from './apiUrls';
import { BaseChunk } from './BaseChunk';

export class StandAloneChunk extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        editable: PropTypes.bool,
    };

    static defaultProps = {
        editable: true,
    };

    state = {
        text: '',
        objectId: null,
        contentType: null,
        canEdit: false,
        isLoading: true,
    };

    isMounted = false;

    componentDidMount = () => {
        this.isMounted = true;
        this.load();
    };

    componentDidUpdate = (prevProps) => {
        if (prevProps.name !== this.props.name) {
            this.load();
        }
    };

    componentWillUnmount() {
        this.isMounted = false;
    }

    handleSave = dataToSave => new Promise((resolve, reject) => {
        callApi(editChunkURL(this.props.name), 'post', dataToSave, FORMDATA)
            .then((response) => {
                this.setState({
                    text: response.text,
                    objectId: response.object_id,
                }, resolve);
            })
            .catch((err) => {
                if (err.status) reject();
            });
    });

    load = () => {
        if (this.isMounted) {
            this.setState({ isLoading: true });
            callApi(loadChunkURL(this.props.name))
                .then((response) => {
                    this.setState({
                        text: response.text,
                        contentType: response.content_type,
                        objectId: response.id,
                        canEdit: response.edit,
                        isLoading: false,
                    });
                })
                .catch((err) => {
                    if (err.status) showErrorNotification('Ошибка при попытке загрузить текст описания');
                });
        }
    };

    render() {
        const { name, ...chunkProps } = this.props;
        return (
            <BaseChunk
                name={ name }
                onHandleSave={ this.handleSave }
                text={ this.state.text }
                contentType={ this.state.contentType }
                objectId={ this.state.objectId }
                canEdit={ this.props.editable && this.state.canEdit }
                isLoading={ this.state.isLoading }
                { ...chunkProps }
            />
        );
    }
}
